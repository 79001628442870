import { Component } from 'react';

export default class NotFoundPage extends Component {
  componentDidMount() {
    window.location = '/';
  }

  render() {
    return null;
  }
}
